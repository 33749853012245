import './Demand.less'
import React, { PureComponent as Component } from 'react'
import { Tabs, Row, Col } from 'antd'
import QueueAnim from 'rc-queue-anim'
import SectionTitle from './Title.jsx'
import pic1 from '@/assets/pic1.png'
import pic2 from '@/assets/pic2.png'
import pic3 from '@/assets/pic3.png'
import pic4 from '@/assets/pic4.png'

const { TabPane } = Tabs

const demands = [{
  pic: pic1,
  title: '降低成本需求',
  content: '专注于解决企业向自由职业者支付所得时碰到的所得性质判定、合规纳税申报、降低税务成本、补全发票链条等迫切需求'
}, {
  pic: pic2,
  title: '合规需求',
  content: '政策要求与业务的匹配，即创新型业务处于监管“真空”，怎么在起量的同时，合规生存针对合作自然人的费用低成本、合规完税、支付与票据取得'
}, {
  pic: pic3,
  title: '运营需求',
  content: '对合作自然人的粘度，特别是核心人群的运营，即人的吸引、增加业绩收入和个体保留 不同职能合作者的综合解决方案，包括组织形态、合作关系定义、协议文本等，降低管理难度和成本'
}, {
  pic: pic4,
  title: '效率需求',
  content: '系统、平台的业务支付一体化对接，提高运营效率'
}]

export default class Demand extends Component {
  render() {
    const { isMobile } = this.props
    return (
      <Row className="content demand" justify="center">
        <Col className="main-content">
          <QueueAnim>
            <SectionTitle key="title1" title="灵活用工企业需求" shadow="FLEXIBLE EMPLOYMENT" class="dark" />
            <Tabs key="content1" tabPosition="left" tabBarGutter={isMobile ? 10 : 50} size="large">
              {
                demands.map((el, i) => (
                  <TabPane key={el.title} tab={`0${i + 1} ${el.title}`}>
                    <Row align="middle">
                      {
                        isMobile ?
                          <Col key={`detail-box-${i}`} className="detail-box" style={{ backgroundImage: `url(${el.pic})` }}>
                            <div className="detail">
                              <h3>{el.title}</h3>
                              <p>{el.content}</p>
                            </div>
                          </Col>
                          :
                          [
                            <Col key={`content1-img-${i}`}>
                              <img src={el.pic} alt=""></img>
                            </Col>,
                            <Col key={`content1-detail-${i}`} className="detail">
                              <h3>{el.title}</h3>
                              <p>{el.content}</p>
                            </Col>
                          ]
                      }
                    </Row>
                  </TabPane>
                ))
              }
            </Tabs>
          </QueueAnim>
        </Col>
      </Row>
    )
  }
}