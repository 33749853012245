import React from 'react';
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import Home from './pages/Home/Index.jsx'

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <div style={{height: '100%'}}>
        <Home />
      </div>
    </ConfigProvider>
  );
}

export default App;
