import './Business.less'
import React, { PureComponent as Component } from 'react'
import { Row, Col } from 'antd'
import { OverPack } from 'rc-scroll-anim'
import QueueAnim from 'rc-queue-anim'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import SectionTitle from './Title.jsx'
import pic1 from '@/assets/solution_pic1.png'
import pic2 from '@/assets/solution_pic2.png'
import pic3 from '@/assets/solution_pic3.png'
import pic4 from '@/assets/solution_pic4.png'
import pic5 from '@/assets/solution_pic5.png'
import icon1 from '@/assets/waimai.png'
import icon2 from '@/assets/zhibo.png'
import icon3 from '@/assets/zhishi.png'
import icon4 from '@/assets/yiliao.png'
import icon5 from '@/assets/invoice_warranty_fill.png'

const solutions = [{
  bg: pic1,
  icon: icon1,
  platform: '外卖平台',
  people: '外卖小哥',
  content: '帮助外卖平台向送餐小哥的服务结算合规化入账，实现外卖平台增值税、所得税双项减负的同时，为送餐小哥合规报税，降税增收。已为美团、饿了么多地代理提供合规服务'
}, {
  bg: pic2,
  icon: icon2,
  iconWidth: 56,
  marginTop: '-10px',
  platform: '直播平台',
  people: '网红主播',
  content: '让视频直播平台向网红主播的打赏分账支出合规入账。帮助直播平台税务合规化，降本增效的同时，为主播网红合规报税、完税，降税增收。目前已与多家直播平台建立合作关系。'
}, {
  bg: pic3,
  icon: icon3,
  iconWidth: 42,
  platform: '付费知识平台',
  people: '自媒体',
  content: '帮助付费知识平台向大V及KOL的有偿内容结算合规入账，实现增值税、所得税双项减负，并为大V，KOL合规报税、完税。'
}, {
  bg: pic4,
  icon: icon4,
  iconWidth: 38,
  platform: '医药行业',
  people: '医药代表',
  content: '对医药行业的人力资源成本进行合理筹划，支出合规入账，实现增值税及所得税的减负，并为医药代表合规低税点完税。'
}, {
  bg: pic5,
  icon: icon5,
  iconWidth: 36,
  platform: '保险科技行业',
  people: '社会化营销人员',
  content: '帮助保险科技平台向社会化营销人员结算平台推广费、线索推荐费时合规入账，节税增效，同时为社会化营销人员合规低税点完税并开具完税证明。'
}]

export default class Business extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0
    }
    this.toggleIndex = this.toggleIndex.bind(this)
  }
  toggleIndex = (direction) => {
    const num = direction === 'prev' && this.state.currentIndex > 0 ? -1 : direction === 'next' && this.state.currentIndex < 4 ? 1 : 0
    if (num !== 0) {
      const currentIndex = this.state.currentIndex + num
      this.setState({
        currentIndex
      })
    }
  }
  render() {
    const currentObj = solutions[this.state.currentIndex]
    return (
      <OverPack>
        <Row className="content business" justify="center">
          <Col style={{ width: this.props.isMobile ? '100%' : '' }} className="main-content">
            <QueueAnim type="bottom" duration={1000}>
              <SectionTitle key="title3" title="行业解决方案" shadow="SOLUTION" desc="突破传统灵活用工，适用于全领域全场景" class="dark" />
              <Row key="content3-1" justify={this.props.isMobile ? 'space-between' : 'flex-start'} align={this.props.isMobile ? 'middle' : ''}>
                {
                  this.props.isMobile ?
                    [
                      <Col
                        key="left-arrow"
                        className={`arrow-icon ${this.state.currentIndex > 0 ? 'arrow-icon-active' : ''}`}
                        onClick={() => this.toggleIndex('prev')}>
                        <LeftOutlined />
                      </Col>,
                      <Col key="center-content" className="single-solution" style={{ backgroundImage: `url(${currentObj.bg})` }}>
                        <div className="content-case">
                          <img src={currentObj.icon} alt="" style={{ width: `${currentObj.iconWidth}px`, marginTop: currentObj.marginTop || 0 }}></img>
                          <h3>{currentObj.platform}与{currentObj.people}</h3>
                          <p>{currentObj.content}</p>
                        </div>
                      </Col>,
                      <Col
                        key="right-arrow"
                        className={`arrow-icon ${this.state.currentIndex < solutions.length - 1 ? 'arrow-icon-active' : ''}`}
                        onClick={() => this.toggleIndex('next')}>
                        <RightOutlined />
                      </Col>,
                    ]
                    :
                    solutions.map(el => (
                      <Col key={el.platform} className="single-solution" style={{ backgroundImage: `url(${el.bg})` }}>
                        <div className="title-case">
                          <img src={el.icon} alt="" style={{ width: `${el.iconWidth}px`, marginTop: el.marginTop || 0 }}></img>
                          <h3>{el.platform}</h3>
                          <h3>与</h3>
                          <h3>{el.people}</h3>
                        </div>
                        <div className="content-case">
                          <h3>{el.platform}与{el.people}</h3>
                          <p>{el.content}</p>
                        </div>
                      </Col>
                    ))
                }
              </Row>
              <Row key="content3-2" justify="center">
                <Col>
                  <button className="view-more-button" onClick={this.props.toggleExperienceModal}>立即咨询</button>
                </Col>
              </Row>
            </QueueAnim>
          </Col>
        </Row>
      </OverPack>

    )
  }
}