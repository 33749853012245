import partner1 from '@/assets/joiner_p1.png'
import partner2 from '@/assets/joiner_p2.png'
import partner3 from '@/assets/joiner_p3.png'
import partner4 from '@/assets/joiner_p4.png'
import partner5 from '@/assets/joiner_p5.png'
import partner6 from '@/assets/joiner_p6.png'
import partner7 from '@/assets/joiner_p7.png'
import partner8 from '@/assets/joiner_p8.png'
import partner9 from '@/assets/joiner_p9.png'
import partner10 from '@/assets/joiner_p10.png'
import partner11 from '@/assets/joiner_p11.png'
import partner12 from '@/assets/joiner_p12.png'
import partner13 from '@/assets/joiner_p13.png'
import partner14 from '@/assets/joiner_p14.png'
import partner15 from '@/assets/joiner_p15.png'

export default [
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  partner6,
  partner7,
  partner8,
  partner9,
  partner10,
  partner11,
  partner12,
  partner13,
  partner14,
  partner15,
]