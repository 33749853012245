import React from 'react';
import backgroundImage from '@/assets/banner.png'

class Banner extends React.PureComponent {
  render() {
    const { banners, ...currentProps } = this.props;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    const banner = banners.length > 0 && banners[0].downloadCode ? `/api/sysmgr-web/file/download?downloadCode=${banners[0].downloadCode}` : backgroundImage
    return (
      <div {...currentProps} className="banner0">
        <img src={banner} alt="banner"></img>
      </div>
    );
  }
}
export default Banner;
