import './Advantage.less'
import React, { PureComponent as Component } from 'react'
import { Row, Col } from 'antd'
import TweenOne from 'rc-tween-one'
import { OverPack } from 'rc-scroll-anim'
import QueueAnim from 'rc-queue-anim'
import icon1 from '@/assets/choose_icon1(1).png'
import icon2 from '@/assets/choose_icon1.png'
import icon3 from '@/assets/choose_icon3.png'
import icon4 from '@/assets/hg_icon2.png'
import icon5 from '@/assets/choose_icon4.png'
import SectionTitle from './Title.jsx'

export default class Advantage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0
    }
    this.getAnimationObj = this.getAnimationObj.bind(this)
    this.getClassName = this.getClassName.bind(this)
    this.updateCurrentIndex = this.updateCurrentIndex.bind(this)
  }
  getAnimationObj(index) {
    if (this.props.isMobile) {
      return null
    }
    return this.state.currentIndex === index ? {
      width: 390,
      height: 280,
      x: -80,
    } : {
      height: 200,
      width: 193,
    }
  }
  getClassName(index) {
    return this.state.currentIndex === index ? 'item active-item' : 'item'
  }
  updateCurrentIndex(index) {
    this.setState({
      currentIndex: index
    })
  }
  render() {
    const advantages = [
      {
        icon: icon1,
        title: '政府深度合作',
        content: '多地税务机关指定委托代征机构',
      },
      {
        icon: icon2,
        title: '合法合规保障',
        content: '拥有会计事务所专业团队设计合规方案',
      },
      {
        icon: icon3,
        title: '资金保障安全',
        content: '资金归属客户公司，客户可随时充值、提现',
      },
      {
        icon: icon4,
        title: '系统结算稳定',
        content: '自由职业者绩效结算秒级到账',
      },
      {
        icon: icon5,
        title: '360度极致风控',
        content: '业务真实性、合规性严格审核，主动拦截风险行为',
      },
    ];
    return (
      <OverPack>
        <Row className="content advantage" justify="center">
          <Col className="main-content">
            <QueueAnim type="bottom" duration={1000}>
              <SectionTitle key="title6" title="我们的优势" shadow="OUR ADVANTAGE" class="light" />
              <div key="content6" className="items">
                {
                  advantages.map((el, i) => (
                    <TweenOne
                      key={el.title}
                      animation={this.getAnimationObj(i)}
                      style={{ transform: this.props.isMobile ? '' : 'translateX(-80px)' }}
                      className={this.getClassName(i)}
                      onMouseEnter={() => { this.updateCurrentIndex(i) }}
                    >
                      <img src={el.icon} alt="" className="advantage-icon"></img>
                      <h3>{el.title}</h3>
                      <p>{el.content}</p>
                    </TweenOne>
                  ))
                }
              </div>
            </QueueAnim>
          </Col>
        </Row>
      </OverPack>

    )
  }
}