import './Scene.less'
import React, { PureComponent as Component } from 'react'
import { Row, Col } from 'antd'
import { OverPack } from 'rc-scroll-anim'
import QueueAnim from 'rc-queue-anim'
import SectionTitle from './Title.jsx'
import icon1 from '@/assets/money_zijinhuabo_line.png'
import icon1_1 from '@/assets/money_zijinhuabo_line2.png'
import icon2 from '@/assets/invoice_warranty_line.png'
import icon2_1 from '@/assets/invoice_warranty_line2.png'
import icon3 from '@/assets/system_edit_line.png'
import icon3_1 from '@/assets/system_edit_line2.png'
import icon4 from '@/assets/customer_list_line.png'
import icon4_1 from '@/assets/customer_list_line2.png'

const apis = [{
  icon: icon1,
  iconHighlight: icon1_1,
  title: '资金收付API',
}, {
  icon: icon2,
  iconHighlight: icon2_1,
  title: '合规鉴证API',
}, {
  icon: icon3,
  iconHighlight: icon3_1,
  title: '电子签约API',
}, {
  icon: icon4,
  iconHighlight: icon4_1,
  title: '实名认证API',
}]

export default class Scene extends Component {
  render() {
    return (
      <OverPack>
        <Row className="content scene" justify="center">
          <Col className="main-content">
            <QueueAnim type="bottom" duration={1000}>
              <SectionTitle key="title4" title="场景" shadow="SCENE" desc="解决业务场景化要求，支持API定制" class="dark" />
              <div key="content4" className="tag-case">
                <div className="tags">
                  {apis.map(el => (
                    <div className="tag" key={el.title}>
                      <img src={el.icon} alt="" className="normal"></img>
                      <img src={el.iconHighlight} alt="" className="highlight"></img>
                      {el.title}
                    </div>
                  ))}
                </div>
                <button className="view-more-button" onClick={this.props.toggleExperienceModal}>查看更多</button>
              </div>
            </QueueAnim>
          </Col>
        </Row>
      </OverPack>

    )
  }
}