import './Partners.less'
import React, { PureComponent as Component } from 'react'
import { Row, Col } from 'antd'
import QueueAnim from 'rc-queue-anim'
import { OverPack } from 'rc-scroll-anim'
import TweenOne from 'rc-tween-one'
import { LeftOutlined, RightOutlined, DownOutlined, UpOutlined } from '@ant-design/icons'
import SectionTitle from './Title.jsx'
import defaultPartners from '@/page-data/partners'


export default class Partners extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 0,
      showPartners: true,
      showAll: false
    }
    this.changeIndex = this.changeIndex.bind(this)
    this.toggleAll = this.toggleAll.bind(this)
  }
  changeIndex = (type, len) => {
    const _this = this
    const types = {
      'prev': this.state.current > 0 ? -1 : 0,
      'next': this.state.current < len - 1 ? 1 : 0
    }
    const num = types[type]
    if (num !== 0) {
      const current = this.state.current + num
      this.setState({
        current,
        showPartners: false
      })
      setTimeout(() => {
        _this.setState({
          showPartners: true
        })
      }, 500)
    }
  }
  toggleAll = () => {
    this.setState({
      showAll: !this.state.showAll
    })
  }
  render() {
    const { pageData } = this.props
    const { files } = pageData
    let partners = (files && files.filter(el => el.fileKey === 'partner').map(el => el.downloadCode ? `/api/sysmgr-web/file/download?downloadCode=${el.downloadCode}` : el.src))
    partners = !partners.length ? defaultPartners : partners
    const showPartners = this.state.showAll ? partners : partners.filter((el, i) => i < 8)
    // 原始数组可分为多少组5个一组的小数组
    const times = Math.ceil(partners.length / 5)
    const newPartners = []
    for(let i = 0; i < times; i++) {
      newPartners.push(partners.slice(i * 5, i * 5 + 5))
    }
    return (
      <OverPack playScale={0.2}>
        <Row className="content partners" justify="center">
          <Col className="main-content">
            <QueueAnim type="bottom" duration={1000}>
              <SectionTitle key="title7" title="合作伙伴" shadow="COOPERATIVE PARTNER" class="dark" />
              <div key="content7" className="partner-case">
                {
                  this.props.isMobile ?
                    [
                      showPartners.map(el => (
                        <div key={el} className="partner">
                          <img src={el} alt=""></img>
                        </div>
                      )),
                      <div key="bottom-line" className="bottom-line" onClick={this.toggleAll}>
                        <div className="line"></div>
                        <div className="middle">
                          {this.state.showAll ? '收起全部' : '展示全部'}
                          <TweenOne 
                            animation={{
                              y: '+=5',
                              yoyo: true,
                              repeat: -1,
                              duration: 1000,
                            }}
                            className="arrow-icon-anim"
                          >
                            {this.state.showAll ? <UpOutlined /> : <DownOutlined />}
                          </TweenOne>
                        </div>
                        <div className="line"></div>
                      </div>
                    ] : [
                      <span
                        key="left-icon"
                        className={`arrow-icon ${this.state.current > 0 ? 'arrow-icon-active' : ''}`}
                        onClick={() => this.changeIndex('prev')}>
                        <LeftOutlined />
                      </span>,
                      <QueueAnim key="partners-wrapper" className="partner-group">
                        {
                          this.state.showPartners ? newPartners[this.state.current].map((el, i) => (
                            <div key={i} className="partner">
                              <img src={el} alt=""></img>
                            </div>
                          )) : null
                        }
                      </QueueAnim>,
                      <span
                        key="right-icon"
                        className={`arrow-icon ${this.state.current < newPartners.length - 1 ? 'arrow-icon-active' : ''}`}
                        onClick={() => this.changeIndex('next', newPartners.length)}>
                        <RightOutlined />
                      </span>
                    ]
                }
              </div>
            </QueueAnim>
          </Col>
        </Row>
      </OverPack>
    )
  }
}
