import './LoginModal.less'
import React, { PureComponent as Component } from 'react'
import { Modal } from 'antd'
import Bg1 from '@/assets/Bg1.png'
import Bg2 from '@/assets/bg2.png'
import Bg3 from '@/assets/Bg3.png'

const bgs = [Bg1, Bg2, Bg3]

export default class LoginModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: -1
    }
    this.jumpSite = this.jumpSite.bind(this)
  }
  hoverIndex = (index) => {
    this.setState({
      currentIndex: index
    })
  }
  jumpSite = () => {
    const { url } = this.props.loginDocs[this.state.currentIndex]
    url && window.open(`//${url}`)
  }
  render() {
    const { loginDocs, visible, onCancel } = this.props
    return (
      <Modal
        visible={visible}
        closable={false}
        footer={null}
        onCancel={onCancel}
        width="auto"
        className="loginModal">
        <div className="loginBox">
          {
            (loginDocs && loginDocs.map((el, i) => (
              <div
                key={`${el.title}-${i}`}
                className={`loginBlock ${this.state.currentIndex === i && loginDocs[i].mode === 2 ? 'activeBlock' : ''}`}
                style={{
                  backgroundImage: `url(${this.state.currentIndex === i && loginDocs[i].mode === 2 ? '' : bgs[i % 3]})`
                }}
                onMouseEnter={() => this.hoverIndex(i)}
                onMouseLeave={() => this.hoverIndex(-1)}
                onClick={this.jumpSite}
              >
                {
                  this.state.currentIndex === i && loginDocs[i].mode === 2 ? (
                    <div className="middleBox">
                      <img src={`/api/sysmgr-web/file/download?downloadCode=${loginDocs[i].files[0].downloadCode}`} alt=""></img>
                    </div>
                  ) : (
                      <div className="normalBox">
                        <div>我是</div>
                        <div>{el.title}</div>
                        <div className="line"></div>
                      </div>
                    )
                }
              </div>
            )))
            || null
          }
        </div>
      </Modal>
    )
  }
}