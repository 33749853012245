import React, { PureComponent as Component } from 'react'
import { Row, Col } from 'antd'
import { OverPack } from 'rc-scroll-anim'
import QueueAnim from 'rc-queue-anim'
import './Solution.less'
import icon1 from '@/assets/steps_icon1.png'
import icon2 from '@/assets/steps_icon2.png'
import icon3 from '@/assets/steps_icon3.png'
import icon4 from '@/assets/steps_icon4.png'
import icon5 from '@/assets/steps_icon5.png'
import industryIcon from '@/assets/customer_luodigongsi_line.png'
import personIcon from '@/assets/customer_report_line.png'
import logo from '@/assets/logo.png'
import arrow1 from '@/assets/arrow1.png'
import arrow2 from '@/assets/arrow2.png'
import SectionTitle from './Title.jsx'

const platformFlow = [{
  title: '企业入驻',
  icon: icon1,
}, {
  title: '发布任务',
  icon: icon2,
}, {
  title: '个人接单',
  icon: icon3,
}, {
  title: '成果预览',
  icon: icon4,
}, {
  title: '绩效核算',
  icon: icon5,
}]
const industry2Company = [{
  title: '1、 发布任务',
  children: <img src={arrow1} alt="" className="rotate"></img>
}, {
  title: '4、验收成果',
  children: <img src={arrow1} alt="" className="rotate"></img>
}, {
  title: '5、支付费用',
  children: <img src={arrow1} alt="" className="rotate"></img>
}, {
  title: '7、发票开具',
  children: <img src={arrow1} alt=""></img>
}]
const company2Person = [{
  title: '2、接受任务',
  children: <img src={arrow1} alt=""></img>
}, {
  title: '3、提交任务',
  children: <img src={arrow1} alt="" className="rotate"></img>
}, {
  title: '6、绩效结算',
  children: <img src={arrow1} alt="" className="rotate"></img>
}, {
  title: '8、完税凭证',
  children: <img src={arrow1} alt=""></img>
}]

// 流程描述+箭头
const flows = (list) => (
  <Col>
    {
      list.map(el => (
        <div key={el.title} className="flow-and-arrow">
          <p>
            {el.title}
          </p>
          {el.children}
        </div>
      ))
    }
  </Col>
)

export default class Solution extends Component {
  render() {
    const { isMobile, pageData } = this.props
    const { files } = pageData
    const logoInfo = (files && files.find(el => el.fileKey === 'logo')) || ''
    const logoSrc = logoInfo && logoInfo.downloadCode ? `/api/sysmgr-web/file/download?downloadCode=${logoInfo.downloadCode}` : logo
    return (
      <div className="content solution-content">
        <OverPack>
          <Row justify="center" className="solution">
            <Col className="main-content">
              <QueueAnim type="bottom" duration={1000}>
                <SectionTitle key="title2" title="共享人力资源解决方案" shadow="SOLUTION" class="light" />
                <div key="content2" className="card">
                  <Row justify="space-between" align="middle">
                    <Col className="box">
                      <img src={industryIcon} alt=""></img>
                      <p>企业</p>
                    </Col>
                    {flows(industry2Company)}
                    <Col className="box main-logo">
                      <div className="logo-box">
                        <img src={logoSrc} alt=""></img>
                      </div>
                    </Col>
                    {flows(company2Person)}
                    <Col className="box">
                      <img src={personIcon} alt=""></img>
                      <p>个人</p>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col>
                      <h3 className="flow-title">平台流程</h3>
                    </Col>
                  </Row>
                  <Row justify="space-between" align="middle">
                    {
                      platformFlow.map((el, i) => (
                        <div key={el.title} className="flow">
                          <Col>
                            <img src={el.icon} alt="" className="circle"></img>
                            <p>{isMobile ? '' : `${i + 1}、` }{el.title}</p>
                          </Col>
                          {i < 4 ? <img src={arrow2} alt="" className="arrow"></img> : ''}
                        </div>
                      ))
                    }
                  </Row>
                  <Row justify="center">
                    <Col>
                      <button className="view-more-button" onClick={this.props.toggleExperienceModal}>立即体验</button>
                    </Col>
                  </Row>
                </div>
              </QueueAnim>
            </Col>
          </Row>
        </OverPack>
      </div>
    )
  }
}