import './Index.less'
import React, { PureComponent as Component } from 'react'
import Demand from './Demand.jsx'
import Solution from './Solution.jsx'
import Risk from './Risk.jsx'
import Scene from './Scene.jsx'
import Business from './Business.jsx'
import Advantage from './Advantage.jsx'
import Partners from './Partners.jsx'
import ExperienceModal from './ExperienceModal.jsx'
import { post } from '@/utils/request'
import { message } from 'antd'

export default class Content extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showExperienceModal: false,
    }
    this.toggleExperienceModal = this.toggleExperienceModal.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  toggleExperienceModal = () => {
    this.setState({
      showExperienceModal: !this.state.showExperienceModal
    })
  }

  async submitForm(values) {
    try {
      const res = await post('/api/oem-official-website/oem/companyApplication/create', { ...values, platformId: Number(window.localStorage.getItem('platformId')) })
      if(res.code > 199 && res.code < 300) {
        message.success('操作成功')
        this.setState({
          showExperienceModal: false
        })
      } else {
        message.error(res.msg || res.message)
      }
    } catch {
      message.error('网络错误')
    }
  }

  render() {
    return (
      <section className="content-section">
        <Demand key="demand" isMobile={this.props.isMobile} pageData={this.props.pageData} />
        <Solution key="solution" isMobile={this.props.isMobile} pageData={this.props.pageData} toggleExperienceModal={this.toggleExperienceModal} />
        <Risk key="risk" isMobile={this.props.isMobile} pageData={this.props.pageData} />
        <Scene key="scene" isMobile={this.props.isMobile} pageData={this.props.pageData} toggleExperienceModal={this.toggleExperienceModal} />
        <Business key="business" isMobile={this.props.isMobile} pageData={this.props.pageData} toggleExperienceModal={this.toggleExperienceModal} />
        <Advantage key="advantage" isMobile={this.props.isMobile} pageData={this.props.pageData} />
        <Partners key="partners" isMobile={this.props.isMobile} pageData={this.props.pageData} />
        <ExperienceModal visible={this.state.showExperienceModal} onCancel={this.toggleExperienceModal} onOk={this.submitForm} />
      </section>
    )
  }
}