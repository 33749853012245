import './Risk.less'
import React, { PureComponent as Component } from 'react'
import { Row, Col } from 'antd'
import { OverPack } from 'rc-scroll-anim'
import QueueAnim from 'rc-queue-anim'
import SectionTitle from './Title.jsx'
import pic1 from '@/assets/hg_icon1.png'
import pic2 from '@/assets/hg_icon2.png'
import pic3 from '@/assets/hg_icon3.png'
import pic4 from '@/assets/hg_icon4.png'

export default class Risk extends Component {
  render() {
    const contents = [{
      title: '政策合规服务',
      detail: '结合地区政策，行业政策，提供业务结构和业务对手最优化的配置，持续政策指导。',
      img: pic1,
    }, {
      title: '业务合规服务',
      detail: '多维度、多层次的鉴证体系全方位为您识别优质合作伙伴，保障平台上的业务合规。',
      img: pic2,
    }, {
      title: '业务监控服务',
      detail: '实时跟踪业务进程，主动拦截风险行为，持续监控合作企业及其股东的负面舆情。',
      img: pic3,
    }, {
      title: '专业定制服务',
      detail: '为您的场景特征设计专属的、符合国家政策要求的业务结构、服务合同、绩效费用等，并负责方案的落地实施。',
      img: pic4,
    }]
    return (
      <OverPack>
        <Row className="content risk" justify="center">
          <Col className="main-content">
            <QueueAnim type="bottom" duration={1000}>
              <SectionTitle key="title3" title="风控系统" shadow="RISK MANAGEMENT" class="dark" />
              <div key="content3" className="detail">
                {contents.map(el => (
                  <div className="part" key={el.title}>
                    <img src={el.img} alt=""></img>
                    <div>
                      <h3>{el.title}</h3>
                      <p>{el.detail}</p>
                    </div>
                  </div>
                ))}
              </div>
            </QueueAnim>
          </Col>
        </Row>
      </OverPack>

    )
  }
}