import './ExperienceModal.less'
import React, { PureComponent as Component } from 'react'
import { Modal, Form, Input } from 'antd'

const { Item } = Form

export default class ExperienceModal extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  render() {
    return (
      <Modal
        visible={this.props.visible}
        title="立即体验"
        onCancel={this.props.onCancel}
        className="experienceModal"
        footer={null}
      >
        <Form labelCol={{span: 6}} wrapperCol={{span: 10}} onFinish={this.props.onOk}>
          <Item label="企业名称" name="companyName" rules={[{ required: true, message: '请填写企业名称' }]}>
            <Input placeholder="请输入" />
          </Item>
          <Item label="联系人" name="contact" rules={[{ required: true, message: '请填写联系人' }]}>
            <Input placeholder="请输入" />
          </Item>
          <Item label="联系人手机" name="mobile" rules={[{ required: true, pattern: /^[1]([3-9])[0-9]{9}$/, message: '请正确填写联系人手机' }]}>
            <Input placeholder="请输入" />
          </Item>
          <div className="custom-footer"><button html-type="submit">提交</button></div>
        </Form>
      </Modal>
    )
  }
}