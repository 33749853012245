import React from 'react';
import TweenOne from 'rc-tween-one';
import { Row, Col, Space, Popover, Button, Drawer } from 'antd';
import { PhoneOutlined } from '@ant-design/icons'
import menuIcon from '@/assets/menu.png'
import LoginModal from './LoginModal.jsx'
import logo from '@/assets/logo.png'

const anchors = [{
  title: '人力资源共享',
  href: 'solution',
}, {
  title: '风控系统',
  href: 'risk',
}, {
  title: '场景',
  href: 'scene',
}, {
  title: '行业解决方案',
  href: 'business',
}, {
  title: '我们的优势',
  href: 'advantage',
}, {
  title: '合作伙伴',
  href: 'partners',
}]

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phoneOpen: undefined,
      showLoginModal: false,
      openDrawer: false,
    }
    this.scrollIntoView = this.scrollIntoView.bind(this)
    this.toggleLoginModal = this.toggleLoginModal.bind(this)
    this.toggleDrawer = this.toggleDrawer.bind(this)
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };

  toggleLoginModal = () => {
    this.setState({
      showLoginModal: !this.state.showLoginModal
    })
  }

  scrollIntoView = (href) => {
    const num = this.props.isMobile ? 0 : -64
    window.scrollTo(0, document.getElementsByClassName(href)[0].offsetTop + num)
  }

  toggleDrawer = () => {
    this.setState({
      openDrawer: !this.state.openDrawer
    })
  }

  render() {
    const { isMobile, pageData } = this.props;
    const { phoneOpen } = this.state;
    const { loginDocs, files } = pageData
    const logoInfo = (files && files.find(el => el.fileKey === 'logo')) || {}
    const logoSrc = logoInfo.downloadCode ? `/api/sysmgr-web/file/download?downloadCode=${logoInfo.downloadCode}` : logo
    const contactContent = () => (<span><PhoneOutlined style={{ marginRight: '12px' }} /> {pageData.mobile} </span>)
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: 'from' }}
        className="header3 home-page-wrapper"
      >
        <Row className={`home-page ${phoneOpen ? ' open' : ''}`} justify="center">
          <Col className="header3-case">
            <TweenOne
              animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
              className="header3-logo"
            >
              <img width="100%" src={logoSrc} alt="" />
            </TweenOne>
            {
              isMobile ?
                [
                  loginDocs && loginDocs.length > 0 ? <span key="login-button" className="login-text" onClick={this.toggleLoginModal}>登录</span> : null,
                  <img src={menuIcon} alt="" key="menu-icon" className="menu-icon" onClick={this.toggleDrawer}></img>,
                  <Drawer
                    key="menu-mobile"
                    placement="right"
                    closable={true}
                    onClose={this.toggleDrawer}
                    visible={this.state.openDrawer}
                  >
                    <div className="menu-mobile">
                      {
                        anchors.map(el => (
                          <p key={`${el.href}-anchor`} onClick={() => this.scrollIntoView(el.href)} className="anchor">{el.title}</p>
                        ))
                      }
                    </div>
                  </Drawer>
                ] :
                [
                  <Space key="header-1" size={50}>
                    {
                      anchors.map(el => (
                        <span key={`${el.href}-anchor`} onClick={() => this.scrollIntoView(el.href)} className="anchor">{el.title}</span>
                      ))
                    }
                  </Space>,
                  <Space key="header-2" className="header-right-section" size={35}>
                    {
                      pageData.mobile ? 
                      <Popover content={contactContent} placement="bottom" key="contact-us-0">
                        联系我们
                      </Popover> : ''
                    }
                    
                    {loginDocs && loginDocs.length > 0 ? <Button type="primary" key="login" onClick={this.toggleLoginModal}>登录</Button> : null}
                  </Space>
                ]
            }
            <LoginModal visible={this.state.showLoginModal} loginDocs={loginDocs} onCancel={this.toggleLoginModal} isMobile={isMobile} />
          </Col>
        </Row>
      </TweenOne>
    );
  }
}

export default Header;
