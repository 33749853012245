import React, { PureComponent as Component } from 'react'

export default class Title extends Component {
  render() {
    return (
      <div className={`${this.props.class} content-title`}>
        <h2>
          {this.props.title}
          </h2>
        <p>{this.props.shadow}</p>
        {this.props.desc ? (<div>{this.props.desc}</div>) : null}
      </div>
    )
  }
}