import Axios from 'axios'

// 统一对返回结果做处理
const checkCode = (data) => {
  console.log(data)
  // if (data.code >= 200 || data.code < 300) {
  //   return data
  // }
  // return Promise.reject(data)
  return data
}

Axios.interceptors.response.use((res) => checkCode(res.data), (err) => checkCode(err.response.data))

const get = (url, params) => Axios.get(url, { params })

const post = (url, data) => Axios.post(url, data)

export { get, post }
